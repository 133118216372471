.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
h1 {
  font-family: "Yeon Sung", cursive;
}
.mint-btn {
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.1s;
  transform: scale(1);
  background: linear-gradient(#440a8f, #5a318f);
  border: none;
  border-radius: 4px;
  color: #fff;
  font-family: "Yeon Sung", cursive;
  font-size: 20px;
  padding: 10px 30px;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.15);
  }
}
.App-header {
  background-color: #fff;
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
  span {
    font-family: "Yeon Sung", cursive;
    font-size: 16px;
  }
}

.App-link {
  color: #61dafb;
}

ul.listOfArts {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  list-style: none;
  li {
    flex: 1;
    max-width: 300px;
    margin: 10px;
    text-align: center;
    font-family: "Yeon Sung", cursive;
    position: relative;
    top: 0;
    transition: all 0.5s;
    border-radius: 6px;
    img {
      border-radius: 6px 6px 0 0;
      width: 100%;
      height: auto;
    }
    .artDescription {
      padding: 10px;
      span {
        display: block;
      }
      button {
        background-color: transparent;
        padding: 4px 12px;
        border: 0;
        border-bottom: 1px solid #e3e3e3;
        font-size: 14px;
        font-family: "Yeon Sung", cursive;
      }
    }
    &:hover {
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
      top: -10px;
    }
  }
}

.layout-modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  .modal-container {
    width: 100%;
    min-height: 30%;
    
    max-width: 400px;
    border-radius: 10px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .content {
      padding: 20px;
      text-align: center;
      img {
        display: block;
        margin: 30px auto;
        width: 200px;
        height: auto;
        border-radius: 10px;
      }
      .your-details-account
      {
        padding: 10px;
      }
      .your-balance
      {
        font-size: 14px;
        display: block;
        padding: 10px;
        border: 1px solid #2fac6640;
        border-radius: 4px;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
